import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CenterSpinner from "../../components/widgets/CenterSpinner";
import { selectSelectedReconcileGroup, setSelectedReconcileGroup } from '../../store/reducers/reconcileGroupSlice';
import { useGetReconcileGroupQuery } from '../../store/reducers/reconcileApi/reconcileGroupApi';
import { setSelectedAgreement } from "../../store/reducers/agreementSlice";

const ReconcileGroupComponent = ({component} : any) => {
    const { reconcileGroupId } = useParams();
    const selectedReconcileGroup = useSelector(selectSelectedReconcileGroup);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const {data: reconcileGroup, isError, isLoading} = useGetReconcileGroupQuery(reconcileGroupId ?? "");

    useEffect(() => {
        if(reconcileGroup){
            dispatch(setSelectedReconcileGroup(reconcileGroup));
            dispatch(setSelectedAgreement(reconcileGroup.agreementId));
        }
    }, [reconcileGroup, dispatch])

    useEffect(() => {
        if(isError)
          navigate("/");
    }, [isError])

    return (
        <React.Fragment>
            {isLoading && <CenterSpinner/>}
            {selectedReconcileGroup && component}
        </React.Fragment>
    )
}
export default ReconcileGroupComponent;