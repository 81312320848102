import { PayloadAction } from '@reduxjs/toolkit';
import { MutationThunkArg } from '@reduxjs/toolkit/dist/query/core/buildThunks';

export const isQuery = (action: PayloadAction<any>) => action.type.includes('executeQuery');
export const isMutation = (action: PayloadAction<any>) => action.type.includes('executeMutation');


interface PayLoadActionProp {
    action: PayloadAction<undefined, string, {
        arg: MutationThunkArg & {
            originalArgs: {
                name: string;
            };
        };
        requestId: string;
        requestStatus: "pending";
    } & {
        startedTimeStamp: number;
    }, never>
} 
export const isCreateAction = ({action} : PayLoadActionProp) => action.meta.arg.endpointName.includes('create');
  