import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';



interface ReconcileState {
    erpSearch: string | undefined;
    bankSearch: string | undefined;
}

const initialState: ReconcileState = {
    erpSearch: undefined,
    bankSearch: undefined,

};


const reconcileSlice = createSlice({
    name : 'reconcile',
    initialState,
    reducers : {
        setErpSearch : (state, action : PayloadAction<string | undefined>) => {
            state.erpSearch = action.payload;
        },
        setBankSearch : (state, action : PayloadAction<string | undefined>) => {
            state.bankSearch = action.payload;
        }
    }
});
export default reconcileSlice.reducer;


export const {setErpSearch, setBankSearch} = reconcileSlice.actions;
export const selectErpSearch = (state : RootState) => state.reconcile.erpSearch;
export const selectBankSearch = (state : RootState) => state.reconcile.bankSearch;