import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LookupResult from '../models/interfaces/LookupResult';
import { BankTransaction, ErpTransaction, TransactionVoucherLine } from '../models/interfaces/Transaction';
import { VoucherAttachment } from '../models/interfaces/Voucher';
import { RootState } from '../store';

interface BookkeepingState {
    clientLookup: LookupResult | undefined;
    vouchers: TransactionVoucherLine[] | undefined;
    canPost : boolean;
    differenceReconcileTransactions : (ErpTransaction| BankTransaction)[] | undefined;
    voucherAttachments : VoucherAttachment[] | undefined;
}

const initialState: BookkeepingState = {
    clientLookup: undefined,
    vouchers: undefined,
    canPost : false,
    differenceReconcileTransactions : undefined,
    voucherAttachments : undefined
};

const bookkeepingSlice = createSlice({
    name : 'bookkeeping',
    initialState,
    reducers : {
        setClientLookup : (state, action : PayloadAction<LookupResult | undefined>) => {
            state.clientLookup = action.payload;
        },
        setVouchers : (state, action : PayloadAction<TransactionVoucherLine[] | undefined>) => {
            state.vouchers = action.payload;
        },
        setCanPost : (state, action : PayloadAction<boolean>) => {
            state.canPost = action.payload;
        },
        setDifferenceReconcileTransactions : (state, action : PayloadAction<(ErpTransaction| BankTransaction)[] | undefined>) => {
            state.differenceReconcileTransactions = action.payload;
        },
        setVoucherAttachments : (state, action : PayloadAction<VoucherAttachment | undefined>) => {
            if(action.payload){
                const currentState = state.voucherAttachments?.find(x => x.voucherId === action.payload?.voucherId);
                if(currentState){
                    currentState.attachments = action.payload.attachments;
                }else{
                    state.voucherAttachments = [...(state.voucherAttachments || []), action.payload];
                }
            }else{
                state.voucherAttachments = action.payload;
            }
            
        }
    }
});
export default bookkeepingSlice.reducer;


export const {setClientLookup, setVouchers, setCanPost, setDifferenceReconcileTransactions, setVoucherAttachments} = bookkeepingSlice.actions;
export const selectVouchers = (state : RootState) => state.bookkeeping.vouchers;
export const selectClientLookup = (state : RootState) => state.bookkeeping.clientLookup;
export const selectCanPost = (state : RootState) => state.bookkeeping.canPost;
export const selectDifferenceReconcileTransactions = (state : RootState) => state.bookkeeping.differenceReconcileTransactions;
export const selectVouchersAttachments = (state : RootState, voucherId : string) => state.bookkeeping.voucherAttachments?.find(x => x.voucherId === voucherId);
export const selectAllVoucherAttachments = (state : RootState) => state.bookkeeping.voucherAttachments;