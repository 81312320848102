import { createApi } from '@reduxjs/toolkit/query/react';
import baseReconcileQuery from './baseReconcileQuery';

export const tagTypes = [
  'account',
  'reconcilegroup',
  'erptransaction',
  'banktransaction',
  'accountbalance',
  'approval',
  'agreementReport',
  'thumbnail',
  'fileImport',
  'chainlink',
  'rule',
  'reconcileGroupPeriod',
  'agreement'
]

const reconcileApi = createApi({
  reducerPath: 'reconcileApi',
  baseQuery: baseReconcileQuery,
  tagTypes: tagTypes,
  endpoints: () => ({}),
})
export default reconcileApi


export const providesList = <R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  parentId?: T | undefined,
  subType?: T | undefined
) => {
  let tags = resultsWithIds
    ? [
      { type: tagType, id: 'LIST' },
      ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
    ]
    : [{ type: tagType, id: 'LIST' }]

  if (parentId)
    tags = [...tags, { type: tagType, id: parentId }]

  if (subType)
    tags = [...tags, { type: tagType, id: subType }]

  return tags;
}
