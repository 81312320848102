import { AnyAction, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { MutationThunkArg } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { RootState } from '../store';

export enum AlertStatus {
  Loading,
  Deleting,
  Success,
  Failed,
  Warning
}

export interface AlertLink {
  text: string,
  link: string
}

export interface Alert {
  alertId: string,
  title: string,
  message?: string,
  status: AlertStatus,
  alertLink?: AlertLink
}

enum ResourceStatus {
  UNKNOWN = 0,
  CREATING = 1,
  CREATED = 2,
  REJECTED = 3,
  DELETING = 4,
  DELETED = 5
}

interface AlertsState {
  alerts: Alert[];
}

const initialState: AlertsState = {
  alerts: [],
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    removeAlert: (state, action: PayloadAction<{ id: string }>) => {
      state.alerts = state.alerts.filter(alert => alert.alertId !== action.payload.id);
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(action => isPending(action) && isMutation(action), (state, action) => {
        const payloadAction = { action } as PayLoadActionProp;
        if ({ action } as PayLoadActionProp) {
          if (isCreateAction(payloadAction)) {
            const { name } = action.meta.arg.originalArgs
            const pendingAlert = createPendingAlert(action.meta.requestId, name, getResourceType(payloadAction));
            state.alerts = [...state.alerts, pendingAlert];
          } else if (isDeleteAction(payloadAction)){
            const deleteObjectId = getResourceIdFromAction(action);
            const pendingAlert = createDeletingAlert(action.meta.requestId, deleteObjectId, getResourceType(payloadAction));
            state.alerts = [...state.alerts, pendingAlert];
          }
        }
      })
      .addMatcher(action => isFulfilled(action) && isMutation(action), (state, action) => {
        const payloadAction = { action } as PayLoadActionProp;
        if ({ action } as PayLoadActionProp) {
          if (isCreateAction(payloadAction)) {
            const { name } = action.meta.arg.originalArgs
            const alertTitle = action.meta.baseQueryMeta?.alertTitle;
            const alertStatus = action.meta.baseQueryMeta?.alertStatus;
            const fulfilledAlert = createNewAlert(ResourceStatus.CREATED, action.meta.requestId, name, getResourceType(payloadAction), alertTitle, alertStatus);
            state.alerts = state.alerts.map(alert => alert.alertId !== action.meta.requestId ? alert : fulfilledAlert);
          } else if (isDeleteAction(payloadAction)){
            const deleteObjectId = getResourceIdFromAction(action);
            const { alertInfo } = action.meta.baseQueryMeta;
            const fulfilledAlert = createNewAlert(ResourceStatus.DELETED, action.meta.requestId, deleteObjectId, getResourceType(payloadAction), alertInfo);
            state.alerts = state.alerts.map(alert => alert.alertId !== action.meta.requestId ? alert : fulfilledAlert);
          }
        }
      })
      .addMatcher(action => isRejected(action) && isMutation(action), (state, action) => {
        const payloadAction = { action } as PayLoadActionProp;
        if ({ action } as PayLoadActionProp) {
          if (isCreateAction(payloadAction)) {
            const { message } = action.payload.data
            const alertTitle = action.meta.baseQueryMeta?.alertTitle;
            const rejectedAlert = createFailedAlert(action.meta.requestId, message, getResourceType(payloadAction), 'create', alertTitle);
            state.alerts = state.alerts.map(alert => alert.alertId !== action.meta.requestId ? alert : rejectedAlert);
          } else if (isDeleteAction(payloadAction)){
            const { message } = action.payload.data
            const alertTitle = action.meta.baseQueryMeta?.alertTitle;
            const rejectedAlert = createFailedAlert(action.meta.requestId, message, getResourceType(payloadAction), 'delete', alertTitle);
            state.alerts = state.alerts.map(alert => alert.alertId !== action.meta.requestId ? alert : rejectedAlert);
          }
        }
      })
  }
});

const getResourceIdFromAction = (action: AnyAction) => {
  const deleteObjectIdKey = Object.keys(action.meta.arg.originalArgs).find(key => key.toLowerCase().endsWith('id'));
  if (deleteObjectIdKey)
    return action.meta.arg.originalArgs[deleteObjectIdKey];
}

interface PayLoadActionProp {
  action: PayloadAction<undefined, string, {
    arg: MutationThunkArg & {
      originalArgs: {
        name: string;
      };
    };
    requestId: string;
    requestStatus: "pending";
  } & {
    startedTimeStamp: number;
  }, never>
}

const getResourceType = ({ action }: PayLoadActionProp) => action.meta.arg.endpointName.replace('create', '').replace('delete', '').replace('update', '');
const isCreateAction = ({ action }: PayLoadActionProp) => action.meta.arg.endpointName.includes('create');
const isDeleteAction = ({ action }: PayLoadActionProp) => action.meta.arg.endpointName.includes('delete');
// const isUpdateAction = ({ action }: PayLoadActionProp) => action.meta.arg.endpointName.includes('update');

const isMutation = (action: PayloadAction<any>) => action.type.includes('executeMutation');

const createDeletingAlert = (requestId: string, message: string | undefined, type: string) => {
  const typeSentenceCase = type.replace(/([A-Z])/g, " $1").toLowerCase();
  const pendingAlert: Alert = {
    title: "Deleting " + typeSentenceCase,
    alertId: requestId,
    message: message,
    status: AlertStatus.Deleting
  }
  return pendingAlert;
}

const createPendingAlert = (requestId: string, message: string | undefined, type: string) => {
  const pendingAlert: Alert = {
    title: "Creating " + toSentenceCase(type),
    alertId: requestId,
    message: message,
    status: AlertStatus.Loading
  }
  return pendingAlert;
}

const createFailedAlert = (requestId: string, message: string | undefined, type: string, action: 'create' | 'delete', title? :  string) => {
  const failedAlert: Alert = {
    alertId: requestId,
    status: AlertStatus.Failed,
    title: title ? title : "Failed to " + action + " " + type,
    message: message
  }
  return failedAlert;
}

const createNewAlert = (status: ResourceStatus, requestId: string, message: string | undefined, type: string, title? : string, alertStatus? : AlertStatus, resourceId?: string) => {
  let newAlert: Alert;
  if (status === ResourceStatus.CREATED || status === ResourceStatus.CREATING) {
    newAlert = {
      alertId: requestId,
      status: alertStatus ? alertStatus : AlertStatus.Success,
      title: title ? title : toSentenceCaseUpper(type) + " created",
      message: message,
    }
    newAlert.alertLink = {
      text: "View " + type,
      link: "/subscriptions/:subscriptionId/services/" + resourceId
    } as AlertLink

  } else if (status === ResourceStatus.DELETED || status === ResourceStatus.DELETING) {
    newAlert = {
      alertId: requestId,
      status: AlertStatus.Success,
      title: title ? title : toSentenceCaseUpper(type) + " deleted.",
      message: message
    }
  } else {
    newAlert = {
      alertId: requestId,
      status: AlertStatus.Failed,
      title: title ? title : "Failed to create " + toSentenceCase(type),
      message: message
    }
  }
  return newAlert
}


const toSentenceCase = (camelCasedText: string) => {
  return camelCasedText.replace(/([A-Z])/g, " $1").toLowerCase().trim();
}

const toSentenceCaseUpper = (camelCasedText: string) => {
  const sentenceCasedText = toSentenceCase(camelCasedText)
  return sentenceCasedText[0].toUpperCase() + sentenceCasedText.slice(1);
}

export const { removeAlert } = alertsSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts.alerts;

export default alertsSlice.reducer;