import { red } from '@mui/material/colors';
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import '../styles/css/roboto.css';

export default createTheme(adaptV4Theme({
  typography: {
    fontFamily: [
      'Roboto',
      'Tahoma',
      'Verdana',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0078d4',
      dark: '#124664',
      light: '#46aae3',
      contrastText: 'rgba(255, 255, 255, 0.98)'
    },
    secondary: {
      main: '#1b2430',
      light: '#121921'
    },
    primaryHome: {
      main: '#4339f2',
      light: '#7069f5',
      contrastText: 'rgba(255, 255, 255, 0.98)'
    },
    secondaryHome: {
      main: '#cc4b00',
      light: '#c75100'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fcfcfc',
      paper: '#fff'
    },
    text: {
      primary: '#213040',
      secondary: '#495866',
      // primary: '#4a4b65',
      // secondary: '#6F7290',
      // hint: 'rgb(0 0 0 / 60%)',
      disabled: 'rgba(0, 0, 0, 0.40)'
    },
    third: {
      main: '#eee',
      dark: '#ddd',
    },
    topBar: {
      main: '#fff',
      contrastText: '#000',
    },
    fourth: {
      main: '#2D2833',
    },
    fifth: {
      main: '#4339f2',
    },
    six: {
      main: '#CCCCCC',
      light: '#6159F4',
    },

    gradient: {
      main: '#fff',
      dark: '#ecebfd'
    },
    widget: {
      main: '#fff',
      dark: '#e1e1e1',
      contrastText: '#000',
    },
    // error: {
    //     main: '#ff4b28',
    //     dark: '#d5391d',
    //     contrastText: '#fff',
    // },
    warning: {
      main: '#FF8C00',
    },
    information: {
      main: '#ace1ff57',
      light: '#e6f6ff',
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: 'rgba(255, 255, 255, 0.95)',
        minWidth: 44,
      },
    },
    MuiListItem: {
      button: {
        paddingTop: 6,
        paddingBottom: 6,
      },
    },
    MuiListSubheader: {
      inset: {
        paddingLeft: 60,
      },
    },
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none',
      },
    },


    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          height: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.3)',
          outline: '1px solid slategrey',
          borderRadius: 2,
        },
      },
    },
  },
} as any));
// export default theme
