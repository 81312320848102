export interface Link {
  method: string;
  rel: string;
  href: string;
}

interface ReconcileGroup {
  id: string;
  name: string;
  resourceUri: string;
  links: Link[];
  agreementId: string;
  openPostsErp: number;
  openPostsBank: number;
  latestErpDate: Date;
  latestBankDate?: any;
  isCurrencyAccount?: boolean;
  reconciliationDate: Date;
  reconciliationType: string;
  reconcileType: string;
  companyName?: string;
  startDate: Date;
}
export default ReconcileGroup;

export const isReconcileGroup = (resource: ReconcileGroup): resource is ReconcileGroup => {
  return resource.reconciliationType !== undefined;
};

export const isReconcileGroups = (
  reconcileGroups: ReconcileGroup[]
): reconcileGroups is ReconcileGroup[] => {
  return (
    (reconcileGroups as ReconcileGroup[]).length > 0 &&
    (reconcileGroups as ReconcileGroup[])[0].reconcileType !== undefined
  );
};
